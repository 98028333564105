<template>
  <div class="contact-us-map">
    <div class="map">
      <img src="/static/img/contactUs/map.png">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.contact-us-map {
  height: 500px;
  background: @imgBgColor;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6.3px rgba(0, 0, 0, -0.013),
    0px 0px 50px rgba(0, 0, 0, 0.05);

  .map {
    height: 100%;
    img {
      height: 100%;
    }
  }
}
</style>
