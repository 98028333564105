<template>
  <div class="contact-us">
    <v-banner-box :data="swiperData">
      <template slot="button">
        <p />
      </template>
    </v-banner-box>
    <v-contact-us />
    <v-recruitment />
  </div>
</template>

<script>
// NOTE :  联系我们
import vBannerBox from '@/components/banner/bannerBox.vue'
import vContactUs from './components/contactUs/index.vue'
import vRecruitment from './components/recruitment/index.vue'
export default {
  components: {
    vBannerBox,
    vContactUs,
    vRecruitment
  },
  watch: {},
  data () {
    return {
      swiperData: [],
      // 搜索框内容
      searchVal: ''
    }
  },
  created () {},
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['联系我们']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
  }
}
</script>

<style lang="less" scoped>
.contact-us {
  margin-bottom: 80px;
}
</style>
