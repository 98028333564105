<template>
  <div class="recruit">
    <div
      class="recruit-item"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="recruit-item-position">
        <div class="icon">
          <img src="/static/img/serviceSupport/qa.png">
        </div>
        <div class="title">
          {{ item.position }}
        </div>
      </div>
      <div class="recruit-item-requirement">
        <div
          class="content"
          v-html="item.requirement"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.recruit {
  background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
  box-shadow: 0px 3px 6px 1px rgba(73, 120, 250, 0.2);
  border: 3px solid #ffffff;
  padding: 50px 40px 0;
  box-sizing: border-box;
  margin-bottom: 80px;
  &-item {
    padding: 20px 0 40px;
    &:not(:last-child){
        border-bottom: 1px solid #7070703d;
    }
    &-position {
      display: flex;
      align-items: center;

      font-size: 20px;
      color: #000;
      font-weight: bold;
      margin-bottom: 22px;
      .icon{
        height: 22px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
        }
      }
    }
    &-requirement {
      font-size: 16px;
      color: #2D3460;
      padding-left: 50px;
      line-height: 30px;
    }
  }
}
</style>
