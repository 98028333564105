<template>
  <div class="recruitment">
    <v-subtitle
      zh="招聘信息"
      en="Recruitment"
      type="mini"
    />
    <div class="recruitment-tel">
      <div class="tel">
        联系方式：17797661556
      </div>
    </div>
    <v-recruit :data="recruit" />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vRecruit from './recruit.vue'
export default {
  components: {
    vSubtitle,
    vRecruit
  },
  data () {
    return {
      recruit: [
        {
          id: 0,
          position: '兰州→web前端工程师',
          requirement: `任职要求：有4年及以上前端研发经验<br/>
                        岗位要求：<br/>
                        1、精通vue、react 前端框架技术，有移动端开发经验 。<br/>
                        2、精通HTML、CSS、javascript、typescript 等前端开发技术。<br/>
                        3、熟悉Web前端组件化、模块化开发模式和前端构建等工程化。<br/>
                        4、实现数据可视化展示熟练使用echarts、HighChart、D3等图表类插件库。<br/>
                        5、熟悉使用websocket解决websocket实时操作带来的性能问题和图表操作的优化。<br/>
                        岗位职责：<br/>
                        1、负责前端界面的构建，各类交互设计与实现。<br/>
                        2、基于Vue、react完成web前端项目，能够开发自定义vue组件。 <br/>
                        3、负责后端API接口对接，并将数据实时展现在系统前端页面 。<br/>
                        4、解决前端架构兼容性问题，能够对现有前端框架进行优化，解决移动端响应式布局问题等。<br/>`
        },
        {
          id: 1,
          position: '兰州→Java开发工程师',
          requirement: `任职要求：有4年及以上JAVA研发经验<br/>
                        岗位要求：<br/>
                        1、负责公司Java Web系统的研发及系统维护工作；<br/>
                        2、独立编写项目开发过程中的项目文档；<br/>
                        3、熟悉Web前端组件化、模块化开发模式和前端构建等工程化。<br/>
                        4、独立完成项目经理分配的开发任务，发现和解决存在的技术问题，保证系统的性能和稳定性；<br/>
                        5、配合解决相关系统运行过程中出现的技术问题。<br/>
                        岗位职责：<br/>
                        1、计算机软件相关专业，三年以上Java Web开发经验，Java基础扎实 ；<br/>
                        2、熟悉Spring MVC、Spring Boot、Spring Cloud、Mybatis等开源框架；<br/>
                        3、至少掌握SQL Server、Oracle、MySQL中一种数据库开发，并有一定调优经验；<br/>
                        4、熟悉Html、CSS、Javascript、Ajax、JQuery、Vue等前端开发技术；<br/>
                        5、有前后端分离合作开发经验，有Node、Vue、React等前端知识储备者优先；<br/>
                        6、做事严谨踏实，有良好的团队合作精神<br/>`
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.recruitment{
  &-tel{
    margin-bottom: 30px;
    display: flex;
    .tel{
      background: linear-gradient(180deg, #F3F5F8 0%, #FEFEFE 100%);
      box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.16);
      border-radius: 21px;
      border: 2px solid #FFFFFF;
      font-size: 20px;
      color: #000;
      padding: 8px 32px;
    }
  }
}
</style>
