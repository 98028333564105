<template>
  <div class="contact-us-info">
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-2.png"
      :img-style="{transform: 'translate(50%, -100%)'}"
    />
    <v-subtitle
      zh="联系我们"
      en="Contact Us"
      type="mini"
    />
    <div class="contact-us-tel">
      <div class="business-affairs contact">
        <div class="title">
          <span>商务联系</span>
        </div>
        <div class="tel">
          <div class="number">
            <span>0931-4267890</span>
          </div>
          <div class="tip">
            为您提供VIP解决方案、行业合作、投诉故迎及业务咨询，ICP备案咨润(7*24小时支持)
          </div>
        </div>
      </div>
      <div class="customer-service contact">
        <div class="title">
          <span>客服电话</span>
        </div>
        <div class="tel">
          <div class="number">
            <span>0931-4267890</span>
          </div>
        </div>
      </div>
    </div>
    <v-map />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vMap from './map.vue'
export default {
  components: {
    vSubtitle,
    vMap
  }
}
</script>

<style lang="less" scoped>
.contact-us {
  &-info {
    padding-top: 50px;
    margin-bottom: 80px;
  }
  &-tel {
    background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    box-shadow: @gBoxShadow;
    border: 3px solid #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92px;
    margin-bottom: 40px;
    .contact{
      padding: 37px 0 0 57px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      &.business-affairs{
        width: 700px;
        border-right: 3px solid #ffffff;
        .tel{
          margin-bottom: 45px;
        }
        .tip{
          font-size: 16px;
          color: #6D7281;
        }
      }
      &.customer-service{
        flex: 1;
      }
      .title{
        position: relative;
        padding-left: 20px;
        color: #000;
        font-size: 18px;
        margin-bottom: 24px;
        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 3px;
          display: block;
          width: 4px;
          height: 18px;
          background: @mainColor;
          margin-right: 10px;
        }
      }
      .tel{
        margin-left: 50px;
        .number{
          font-size: 20px;
          color: @mainColor;
          display: flex;
          flex-direction: column;
          margin-bottom: 4px;
          span+span{
            margin-top: 5px;
          }
        }
        .tip{
          font-size: 12px;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
